.switch {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.1rem;
}

.switch .sum {
  font-size: 0.18rem;
  color: #888888;
}

.switch .switch_box {
  display: flex;
}

.switch .switch_box .item {
  width: 0.36rem;
  height: 0.36rem;
  background-color: #f5f5f5;
  border-radius: 0.03rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.1rem;
  color: #bbb;
  cursor: pointer;
  transition: .3s;
}

.switch .switch_box .item .icon {
  font-size: 0.26rem;
  color: #bbb;
}

.switch .switch_box .item:hover,
.switch .switch_box .item.active {
  background-color: #e5e5e5;
}

.switch .switch_box .item:hover .icon,
.switch .switch_box .item.active .icon {
  color: #888;
}